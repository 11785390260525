import React, { useEffect, useState } from 'react'
import DashboardRoute from './DashboardRoute'
import Trips from 'app/components/trips/TripsPage'
import Vehicles from 'app/components/vehicles/VehiclesPage'
import DriversPage from 'app/components/drivers/DriversPage'
import posthog from 'posthog-js'
import * as selectors from 'app/selectors'
import { useSelector } from 'react-redux'
import Client from 'mz-sdk/client'
import { REFRESH_TOKEN_ENDPOINT } from 'app/constants/endpoints'

export const useToken = () => localStorage.getItem('mozio-auth-token')

const ProvidersV2 = () => {
  const [token, setToken] = useState(null)

  // TEMPORARY: Refresh token solution
  useEffect(() => {
    ;(async () => {
      const response = await Client.post(REFRESH_TOKEN_ENDPOINT, {
        body: {
          refresh_token: localStorage.getItem('mozio-refresh-token'),
        },
      })
      setToken(response.token)
    })()
  }, [])

  if (!token) {
    return <div>Loading...</div>
  }

  const baseUrl =
    process.env.CONFIG_ENV === 'production'
      ? posthog.isFeatureEnabled('allow-production-test')
        ? 'https://providers-v2.mozio.com/providers-dashboard/reports'
        : 'https://mozio-front-end-provider-dashboard-5hubqoeec-mozio.vercel.app/analytics'
      : 'https://providers-v2-staging.mozio.com/providers-dashboard/reports'
  const url = `${baseUrl}?token=${token}`

  return (
    <iframe src={url} width="100%" height="100%" style={{ border: 'none' }} />
  )
}

const PricingV2 = ({ location }) => {
  const token = useToken()
  const { providerId } = useSelector(selectors.getProviderInformation)
  const baseUrl =
    process.env.CONFIG_ENV === 'production'
      ? 'https://providers-v2.mozio.com/providers-dashboard'
      : 'https://providers-v2-staging.mozio.com/providers-dashboard'
  const url = `${baseUrl}${location.pathname}?token=${token}&provider_id=${providerId}`

  return (
    <iframe
      src={url}
      width="100%"
      height="100%"
      style={{ border: 'none', overflow: 'hidden' }}
    />
  )
}

export const routes = [
  {
    path: '/trips/:hash?',
    pathTo: '/trips',
    name: 'Trips',
    icon: 'swap_calls',
    component: Trips,
  },
  {
    path: '/drivers',
    pathTo: '/drivers',
    name: 'Drivers',
    icon: 'group',
    component: DriversPage,
  },
  {
    path: '/vehicles',
    pathTo: '/vehicles',
    name: 'Vehicles',
    icon: 'directions_car',
    component: Vehicles,
  },
  {
    path: '/pricing',
    pathTo: '/pricing',
    name: 'Pricing',
    icon: 'local_offer',
    badge: 'New',
    component: PricingV2,
  },
  {
    path: '/reports',
    pathTo: '/reports',
    name: 'Reports',
    icon: 'pie_chart',
    badge: 'New',
    component: ProvidersV2,
  },
]

const DashboardRoutes = () => {
  return routes.map((route, index) => (
    <DashboardRoute key={index} exact {...route} />
  ))
}

export default DashboardRoutes

import React, { useEffect, useMemo } from 'react'
import { Container, Item, Badge } from './elements'
import { withRouter } from 'react-router-dom'
import { routes } from 'app/routes/DashboardRoutes'
import Icon from 'app/components/commons/Icon'
import { usePriceUpdatePageAccessFeatureFlag } from 'app/hooks/useFeatureFlag'
import posthog from 'posthog-js'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'app/selectors'
import { getProviderInfo } from 'app/actions/session'

const Sidebar = ({ location }) => {
  const dispatch = useDispatch()
  const { providerName, providerId } = useSelector(
    selectors.getProviderInformation
  )

  useEffect(() => {
    if (!providerName && !providerId) {
      dispatch(getProviderInfo())
    }
  }, [])

  const isPriceUpdateEnabled = usePriceUpdatePageAccessFeatureFlag(
    providerName,
    providerId
  )

  const filteredRoutes = useMemo(
    () =>
      routes.filter((route) =>
        route.path === '/pricing' ? isPriceUpdateEnabled : true
      ),
    [isPriceUpdateEnabled]
  )
  return (
    <Container>
      {filteredRoutes.map((route, index) => {
        const badgeFeatureFlag = () => {
          switch (route.path) {
            case '/pricing':
              return posthog.isFeatureEnabled('price_update_page_announcement')
            case '/reports':
              return posthog.isFeatureEnabled('reports_page_announcement')
            default:
              return false
          }
        }
        const showBadge = route.badge && badgeFeatureFlag()
        return (
          <Item
            key={index}
            to={route.pathTo}
            $active={location.pathname.includes(route.pathTo)}
          >
            <Icon> {route.icon} </Icon>
            {route.name}
            {showBadge && <Badge>{route.badge}</Badge>}
          </Item>
        )
      })}
    </Container>
  )
}

export default withRouter(Sidebar)
